<template>
    <div>
        <vue-title title="Вход | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsRoutes"/>
        <div class="container py-4">
            <div class="long-title text-center mb-3">
                <h1 class="page-title">Вход</h1>
            </div>
            <div class="row">
                <div class="col-12 col-md-2 col-lg-3"></div>
                <div class="col-12 col-md-8 col-lg-6">
                    <div id="form-container">
                        <div v-if="errorMessage" class="alert alert-danger mb-3" role="alert">
                            <strong>Внимание!</strong> {{ errorMessage }}
                        </div>
                        <form class="login-form" @submit.prevent="onSubmit">
                            <DxForm
                                    id="form"
                                    :col-count="1"
                                    :form-data="formData"
                                    :read-only="false"
                                    :show-colon-after-label="true"
                                    :show-validation-summary="true"
                                    :label-location="labelLocation"
                                    :disabled="isLoading"
                            >
                                <DxSimpleItem data-field="Login">
                                    <DxLabel text="Логин"/>
                                    <DxRequiredRule message="Укажите ваш логин"/>
                                </DxSimpleItem>
                                <DxSimpleItem
                                        data-field="Password"
                                        :editor-options="passwordOptions"
                                >
                                    <DxLabel text="Пароль"/>
                                    <DxRequiredRule message="Укажите ваш пароль"/>
                                </DxSimpleItem>
                                <DxGroupItem :colCount="2" :alignItemLabels="true">
                                    <DxColCountByScreen :xs="2" :sm="2"/>
                                    <DxButtonItem
                                            horizontalAlignment="left"
                                            cssClass="pt-3"
                                            :button-options="buttonResetPasswordPasswordOptions"
                                    />
                                    <DxButtonItem
                                            cssClass="pt-3"
                                            :button-options="buttonLoginOptions"
                                    />
                                </DxGroupItem>
                            </DxForm>
                        </form>
                    </div>
                    <hr>
                    <div class="text-center">Еще не зарегистрированы?
                        <DxButton
                                text="Регистрация"
                                type="default"
                                styling-mode="outlined"
                                @click="openRegistration"
                                class="ml-3"
                        />
                    </div>
                </div>
                <div class="col-12 col-md-2 col-lg-3"></div>
                <div class="col-12 col-md-2"></div>
                <div class="col-12 col-md-8 mt-5 small">
                    <p>
                        * Внимание! Пароль для входа в Личный кабинет открывает доступ ко всей информации по Вашему
                        договору. Пожалуйста, будьте бдительны и никому не сообщайте данный пароль.
                    </p>
                    <p>
                        ** В целях безопасности по истечении 60 минут неактивности сессия работы в Личном кабинете будет
                        автоматически закрыта.<br>
                        Если Вы закончили работу, обязательно корректно выйдите из личного кабинета, нажав кнопку
                        «Выйти».
                    </p>
                    <p>
                        *** Для корректной работы сайта в настройках веб-браузера должна быть разрешена работа с <a
                            href="https://ru.wikipedia.org/wiki/JavaScript" target="_blank"><strong>JavaScript</strong></a>
                        и
                        <a href="https://ru.wikipedia.org/wiki/HTTP_cookie" target="_blank"><strong>HTTP cookie</strong></a>.
                    </p>
                </div>
                <div class="col-12 col-md-2"></div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import headerRoutes from '../header-routes';
    import {mapGetters} from 'vuex';
    import {httpClient} from '../../../shared/services';
    import {Header, Footer} from '../../../shared/components';
    import {
        DxForm,
        DxSimpleItem,
        DxLabel,
        DxButtonItem,
        DxRequiredRule,
        DxGroupItem,
        DxColCountByScreen
    } from 'devextreme-vue/form';
    import DxButton from 'devextreme-vue/button';

    export default {
        name: 'Login',
        components: {
            Header,
            Footer,
            DxForm,
            DxSimpleItem,
            DxLabel,
            DxButtonItem,
            DxRequiredRule,
            DxButton,
            DxGroupItem,
            DxColCountByScreen
        },
        mounted() {
            window.errorMessage = "";
        },
        data() {
            return {
                headerRoutes,
                formData: {},
                labelLocation: 'top',
                passwordOptions: {
                    mode: 'password'
                },
                buttonLoginOptions: {
                    text: 'Войти',
                    type: 'default',
                    useSubmitBehavior: true
                },
                buttonResetPasswordPasswordOptions: {
                    text: 'Забыли пароль?',
                    type: 'default',
                    stylingMode: 'text',
                    onClick: this.openResetPasswordPassword
                },
                errorMessage: ""
            }
        },
        methods: {
            onSubmit: async function () {
                this.errorMessage = "";
                this.$store.dispatch('loading', true).then(() => {
                    const {Login, Password} = this.formData;

                    httpClient.get("v2/Agent/Login", {
                        headers: {
                            'Login': Login,
                            'Password': Password
                        }
                    }).then((response) => {
                        response.data['login'] = Login;

                        this.$store.dispatch('logIn', response.data).then(() => {
                            this.$store.dispatch('loading', false);
                            if(response.data.isVerified) {
                                this.$router.push(this.$route.query.redirect || '/');
                            } else {
                                this.openVerified();
                            }
                        });
                    }).catch(this.handleError);
                });
            },
            handleError: function (error) {
                this.errorMessage = "Неверно указан Логин или Пароль.";
                this.$store.dispatch('loading', false);
                console.error(error);
            },
            openVerified() {
                if (this.$route.query.redirect) {
                    this.$router.push({name: 'Verified', query: {redirect: this.$route.query.redirect}});
                } else {
                    this.$router.push({name: 'Verified'});
                }
            },
            openRegistration() {
                if (this.$route.query.redirect) {
                    this.$router.push({name: 'Registration', query: {redirect: this.$route.query.redirect}});
                } else {
                    this.$router.push({name: 'Registration'});
                }
            },
            openResetPasswordPassword() {
                if (this.$route.query.redirect) {
                    this.$router.push({name: 'ResetPassword', query: {redirect: this.$route.query.redirect}});
                } else {
                    this.$router.push({name: 'ResetPassword'});
                }
            }
        },
        computed: {
            ...mapGetters([
                'isLoading'
            ])
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
